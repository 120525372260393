import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-password-strength-bar',
  templateUrl: './password-strength-bar.component.html',
  styleUrls: ['./password-strength-bar.component.scss'],
  standalone: true
})
export class PasswordStrengthBarComponent implements OnChanges {

  private colors = ['#ef6565', '#ffa500', '#ffcb3d', '#c7ea71', '#97cc2e'];

  @Input() protected passwordToCheck: string;
  @Output() passwordValidatedEvent = new EventEmitter<any>();

  protected bar0: string;
  protected bar1: string;
  protected bar2: string;
  protected bar3: string;
  protected bar4: string;

  private static measureStrength(pass: string) {
    let score = 0;

    if (pass.length >= 8) {
      score += 20;
    }

    if (/[a-z]/.test(pass)) {
      score += 20;
    }

    if (/[A-Z]/.test(pass)) {
      score += 20;
    }

    if (/\W/.test(pass)) {
      score += 20;
    }

    if (/\d/.test(pass)) {
      score += 20;
    }

    return Math.trunc(score);
  }

  private getColor(score: number) {
    let idx = 0;
    if (score > 90) {
      idx = 4;
    } else if (score > 70) {
      idx = 3;
    } else if (score >= 40) {
      idx = 2;
    } else if (score >= 20) {
      idx = 1;
    }
    return {
      score: score,
      idx: idx + 1,
      col: this.colors[idx]
    };
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    let password = changes?.passwordToCheck?.currentValue;
    this.setBarColors(5, '#DDD');

    if (password) {
      const c = this.getColor(PasswordStrengthBarComponent.measureStrength(password));
      this.setBarColors(c.idx, c.col);
      this.passwordValidatedEvent.emit(c);
    }
  }

  private setBarColors(count: number, col: string): void {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }
}
