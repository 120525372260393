import { NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalioTelInputComponent } from '@calio-lib/calio-intl-tel-input/calio-tel-input/calio-tel-input.component';
import { PasswordStrengthBarComponent } from '@components/password-strength-bar/password-strength-bar.component';
import { COMPANY_SIZE, INDUSTRY_LIST } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { PartnerFormModel } from '@models/partner-form.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AddRequiredPipe } from '@pipe/add-required.pipe';
import { StaticTranslationSortPipe } from '@pipe/static-translation-sort.pipe';
import { HelperService } from '@services/helper.service';
import { LoggerService } from '@services/logger.service';

@Component({
  selector: 'app-personal-data-step',
  templateUrl: './personal-data-step.component.html',
  styleUrls: ['./personal-data-step.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, NgSelectModule, CalioTelInputComponent, PasswordStrengthBarComponent, TranslateModule, AddRequiredPipe, StaticTranslationSortPipe]
})
export class PersonalDataStepComponent implements OnInit {

  @Input() email: string;
  @Input('personalFormError') set personalForm(reset: boolean) {
    reset && this.resetPersnalDataForm();
  };
  @Output() onNextEvent = new EventEmitter<PartnerFormModel>();

  private helperService = inject(HelperService);

  protected readonly redirectUrl = environment.redirectDomain;
  protected readonly industryList = INDUSTRY_LIST;
  protected readonly companySize = COMPANY_SIZE;

  protected partnerFormModel = new PartnerFormModel();
  protected showPasswordDetails: boolean;
  protected accountCreated$ = signal(false);
  protected showLoader$ = signal(false);
  protected showText$ = signal(true);
  protected isPasswordValid$ = signal(false);

  ngOnInit(): void {
    this.getUserCountry();
  }

  protected resetPersnalDataForm(): void {
    this.showLoader$.set(false);
    this.showText$.set(true);
  }

  private getUserCountry(): void {
    this.helperService.getUserCountry().subscribe({
      next: userdata => {
        if (userdata?.country) {
          this.partnerFormModel.country_identifier = userdata.country.toLowerCase();
          this.partnerFormModel.timezone = userdata.timezone;
          this.partnerFormModel.country_code = userdata.country;
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.warn("IP lookup service throwing error");
        LoggerService.error(error);
      }
    })
  }

  protected savePartnerData(): void {
    this.showLoader$.set(true);
    this.showText$.set(false);

    const result: PartnerFormModel = {
      name: this.partnerFormModel.name,
      email: this.email,
      prename: this.partnerFormModel.prename,
      lastname: this.partnerFormModel.lastname,
      password: this.partnerFormModel.password,
      password_repeat: this.partnerFormModel.password,
      country_identifier: this.partnerFormModel.country_identifier,
      country_code: this.partnerFormModel.country_code,
      timezone: this.partnerFormModel.timezone,
      industry: this.partnerFormModel.industry,
      company_size: this.partnerFormModel.company_size,
      phone: this.partnerFormModel?.phone || undefined
    };
    this.onNextEvent.emit(result);
  }

  protected validatePassword(event: { score: number, idx: number, col: string }): void {
    this.isPasswordValid$.set((event.score >= 90));
  }
}
