<div class="final-step">
  <div class="text-center icon-block">
    <div class="notification-icon mt-3">
      <img src="/assets/images/fireworks.svg" alt="success"/>
    </div>
  </div>

  <div class="title">
    {{ 'create_partner_component.success_title' | translate }}
  </div>

  <div class="subtitle">
    <span [innerHtml]="'create_partner_component.successfully_account_created' | translate | calioSafe: 'html'"></span>
    <br /><br />
  </div>

  @if (false) {
    <!-- Intentionally used falsy condition due to new support verification flow -->
    @if (!(blockForTimeMinutes$() || blockForTimeSeconds$() || statusMessage$())) {
      <div class="subtitle mt-3">
        @if (!isRessendEmailSend$()) {
          {{ 'create_partner_component.not_get_mail' | translate }}
          <span class="link" (click)="resendMail()">{{ 'create_partner_component.send_again' | translate }}</span>
        } @else {
          <span class="success-email-send">{{ 'create_partner_component.verify_email_send' | translate }}</span>
          <div>{{ 'create_partner_component.send_again_in' | translate : { 'time': remainingTime$() } }}</div>
        }
      </div>
    }

    <!-- Handel Error -->
    @if (statusMessage$()) {
      <div class="custom-form-error text-center">
        {{ 'error_messages.exceptions.' + statusMessage$() | translate }}
      </div>
    }

    <!-- Handel TooManyRequestsException-->
    @if (blockForTimeMinutes$() || blockForTimeSeconds$()) {
      <div class="custom-form-error text-center">
        @if (blockForTimeMinutes$()) {
          {{ 'error_messages.exceptions.' + ((blockForTimeMinutes$() === 1) ? 'blockforminute' : 'blockforminutes') | translate
          : { time: blockForTimeMinutes$() } }}
        }
        @if (blockForTimeSeconds$()) {
          {{ 'error_messages.exceptions.' + ((blockForTimeSeconds$() === 1) ? 'blockforsecond' : 'blockforseconds') | translate
          : {
          time: blockForTimeSeconds$() } }}
        }
      </div>
    }

    <div class="text-center mt-5">
      <a class="button" [href]="redirectDomain" target="_blank">
        {{ 'create_partner_component.go_to_dashboard' | translate }}
      </a>
    </div>
  }
</div>