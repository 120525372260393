import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, inject, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { CreatePartnerComponent } from '@app/create-partner/create-partner.component';
import { environment } from '@environments/environment';
import { HttpInterceptor } from '@interceptor/http-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { HttpClientService } from '@services/http-client.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const getLocalStorage = (): Storage | null => {
  return typeof window !== 'undefined' ? window.localStorage : null;
};

@NgModule({
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RecaptchaV3Module
  ],
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideHttpClient(withInterceptors([HttpInterceptor])),
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    HttpClientService,
  ]
})
export class AppModule {
  private injector = inject(Injector);

  ngDoBootstrap(): void {
    const el = createCustomElement(CreatePartnerComponent, {
      injector: this.injector,
    });
    customElements.define('calio-create-partner', el);
  }
}
